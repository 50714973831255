import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// naive-ui
import { useMessage } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// services
import botsService from '@services/bots';

// shared
import { BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

export default function () {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const message = useMessage();

    // vars
    const dataForm = bots.dataForm;
    const startFiltersAutoInversionValue = ref();
    const show = ref(dataForm.algo_autoinversion_enabled);
    const collapse = ref();
    const messageInfo = reactive({
        type: '',
        msg: '',
    });
    const botsAutoInvRefs = ref();
    const startFiltersAutoInversionWhiteList = [
        'algo_switch:last_rate:change',
        'algo_switch:rate:change',
        'algo_switch:time:change',
        'algo_switch:time_fix:change',
        'algo_switch:not_add_depo:bool',
    ];

    const botsAutoInvListOptions = computed(() => botsAutoInvRefs.value
        ? botsAutoInvRefs.value?.botsAutoInvList.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })) : []);

    const startFiltersAutoInversionOptions = computed(() => bots.startFiltersAutoInversion.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })).filter(el => !~hasFilter(el.id)));

    const helpers = computed(() => ({
        'algo_switch:last_rate:change': bots.localization.percentage_of_price_change_help || '% изменения цены',
        'algo_switch:rate:change': bots.localization.percentage_of_price_change_from_the_entry_price_of_the_trade_help || '% изменения цены',
        'algo_switch:time:change': bots.localization.time_in_hours_help || 'Время в часах',
        'algo_switch:time_fix:change': bots.localization.duration_of_the_cycle_in_hours_from_placing_the_take_profit_order_help || 'Время в часах',
        'algo_switch:not_add_depo:bool': bots.localization['do_not_use_the_remaining_deposit_from_the_previous_coup_help'] || 'Не использовать остаток депозита от предыдущего переворота',
    }));

    watch(() => dataForm.settings.simulate, v => {
        dataForm.algo_autoinversion_enabled = false;
        dataForm.algo_autoinversion_bot_id = -1;
        bots.startFiltersAutoInversion.forEach(el => {
            const index = dataForm.start_filters.findIndex(({ id }) => id == el.id);

            if (~index) {
                dataForm.start_filters.splice(index, 1);
            }
        });
    });

    const hideCollapse = () => {
        show.value = false;
        botsAutoInvRefs.value = null;
        messageInfo.type = '';
    };

    const syncBotsAutoInvList = async () => {
        if (!dataForm.algo_autoinversion_enabled) return;

        gl.showLoading = true;
        const botInfo = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        delete botInfo.notifications;

        let result;

        try {
            result = await botsService.getBotsAutoInvList(botInfo);
        } catch {
            message.error(t('errorMessage'));
        }

        messageInfo.type = !result?.data.status ? 'error' : 'success';
        messageInfo.msg = result.data.message;

        if (!result?.data.status) {
            // show messages
            // message['error'](data.data.message);
          
        } else {
            botsAutoInvRefs.value = result.data;
        }

        gl.showLoading = false;
    };

    watch(() => dataForm.algo_autoinversion_enabled, async v => {
        if (v) {
            await syncBotsAutoInvList();
            // setStartFiltersAutoInversionValue();
        } else {
            hideCollapse();
        };
    });

    const setStartFiltersAutoInversionValue = () => {
        startFiltersAutoInversionValue.value =
            bots.startFiltersAutoInversion
                .filter(({ id }) => ~hasFilter(id))
                .map(({ id }) => id);
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const updateStartFiltersAutoInversionValue = () => {
        // dataForm.start_filters.forEach((filter, index) => {
        //     const id = bots.startFiltersAutoInversion.findIndex(({ id }) => id == filter.id);
        //     const hasIn = startFiltersAutoInversionValue.value.findIndex(id => id == filter.id);

        //     if (~id) {
        //         if (!~hasIn) {
        //             return void dataForm.start_filters.splice(index, 1);
        //         }
        //     }
        // });

        // startFiltersAutoInversionValue.value.forEach(id => {
        //     const idx = dataForm.start_filters.findIndex(filter => filter.id == id);

        //     if (!~idx) {
        //         dataForm.start_filters.push({
        //             id,
        //             id_op: '==',
        //             value: null,
        //         });
        //     }
        // });

        // console.log('dataForm.start_filters', dataForm.start_filters);
    };

    const onDeleteStartFilter = $event => {
        dataForm.start_filters.splice($event, 1);
    };

    const onAddNewStartFiltersAutoInversion = () => {
        dataForm.start_filters.push({ id: startFiltersAutoInversionValue.value, id_op: '==', value: null });
        startFiltersAutoInversionValue.value = null;
    };

    const gitTitle = id => {
        const el = bots.startFiltersAutoInversion.find(el => el.id == id);

        return el ? el.title : '';
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            if (id == 'algo_switch:not_add_depo:bool') {
                dataForm.start_filters.push(
                    {
                        id,
                        id_op: '==',
                        value: true,
                    });
            } else {
                dataForm.start_filters.push(
                    {
                        id,
                        id_op: '==',
                        value: null,
                    });
            }
        }
    };

    onMounted(async () => {
        // desktop only
        syncBotsAutoInvList();
        // await setStartFiltersAutoInversionValue();
    });

    return {
        gl,
        bots,
        refs,
        show,
        helpers,
        collapse,
        dataForm,
        messageInfo,
        botsAutoInvListOptions,
        startFiltersAutoInversionValue,
        startFiltersAutoInversionOptions,
        startFiltersAutoInversionWhiteList,
        t,
        hasFilter,
        gitTitle,
        triggerFilter,
        onDeleteStartFilter,
        onAddNewStartFiltersAutoInversion,
        updateStartFiltersAutoInversionValue,
    };
}