// vue
import { ref, computed, watch, reactive } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// naive-ui
import {
    useMessage,
    useNotification } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// services
import botsService from '@services/bots';

// shared
import { BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

export default function (props) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const message = useMessage();
    const notification = useNotification();

    // vars
    const show = ref(false);
    const showStartTime = ref(false);
    const dataForm = bots.dataForm;
    const botShortInfo = ref();
    const orderMatrixLoading = ref(false);
    const viewOrdersMatrixModal = ref(false);
    const filtersList = ['start_time:check:time', 'completed_cycles:check:count'];

    const isSkalpingModeAvail = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.scalping);

    const isWalletTypePercent = computed(() => {
        if (!props.walletsRefs) return -1;
        const wallet = props.walletsRefs.find(({ id }) => id == dataForm.wallet.value);
        return wallet?.type == 2;
    });

    const revenueToDepoFactorOptions = computed(() => bots.settingsRefs.revenue_to_depo_factors.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const logarithmicFactorOptions = computed(() => bots.settingsRefs.logarithmic_factor.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const firstPartialOrderOptions = computed(() => bots.settingsRefs.fix_first_partial_order_value.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const autorestartOptions = computed(() => bots.errorAutorestartTypes.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const profitOptions = computed(() => bots.settingsRefs.profit.map(el => ({
        label: el.title + '',
        value: el.id + '',
        ...el,
    })));

    const showAddProfitAdvanced = computed(() => {
        if (!dataForm.settings.profit_advanced) {
            return false;
        }

        return dataForm.settings.profit_advanced[dataForm.settings.profit_advanced.length - 1]?.order_i != compMatrixMaxCount.value
            && dataForm.settings.profit_advanced_enabled;
    });

    const profitAdvancedOptions = computed(() => {
        const result = [];

        if (dataForm.settings.profit_advanced?.length) {
            dataForm.settings.profit_advanced.forEach((el, i) => {
                const options = [];
                const order_i =
                    i == 0
                        ? 2
                        : +(dataForm.settings.profit_advanced[i - 1].order_i) + 1;

                for (let i = order_i; i <= compMatrixMaxCount.value; i++) {
                    options.push({
                        label: i,
                        value: i + '',
                    });
                }

                if (options.length)
                    result.push(options);
            });
        }

        return result;
    });

    const compMatrixMaxCount = computed(() => {
        const ordersMatrix = bots.ordersMatrix.find(({ id }) => id == dataForm.settings.order_matrix);

        const matrix_count = parseInt(ordersMatrix?.matrix_count || ordersMatrix?.matrix.length, 10);

        return matrix_count;
    });
    const compMatrixMaxCountAllowed = computed(() => compMatrixMaxCount.value > 1);
    const partOrderValueShow = computed(() => dataForm.settings.order_matrix != -1 && compMatrixMaxCountAllowed.value);

    watch(() => dataForm.settings.profit_advanced_enabled, v => {
        if (v) {
            addProfitAdvanced();
        } else {
            dataForm.settings.profit_advanced = [];
        }
    });

    watch(() => dataForm.settings.order_matrix, () => {
        dropProfitAdvanced();
    });

    watch(() => Object.keys(bots.innerForms).length, v => {
        if (v > 0) {
            const firstKey = Object.keys(bots.innerForms)[0];

            if (filtersList.includes(firstKey)) {
                show.value = true;

                setTimeout(() => {
                    const selector = firstKey.split(':').join('_');
                    const el = document.querySelector(`#${selector}`);
                    
                    if (el)
                        el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                }, 1000);
            }
        }
    });

    const onViewOrderMatrix = async idOrdersMatrix => {
        orderMatrixLoading.value = true;
        // eslint-disable-next-line no-param-reassign
        idOrdersMatrix = idOrdersMatrix || -1;

        const params = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        delete params.notifications;
        delete params.notifications_contacts;

        let result;

        try {
            result = await botsService.getOrdersMatrixForLogarithmicFactor(params);
        } catch {
            message.error(t('errorMessage'));
        }
      
        if (result) {
            if (result?.msg) {
                notification.error({
                    content: result.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
            if (!result?.data?.status) {
                // show messages
                if (result?.postMessages)
                    result.postMessages.forEach(el => {
                        message[el.success ? 'success' : 'error'](el.msg);
                    });
            } else {
                botShortInfo.value = result.data;
                viewOrdersMatrixModal.value = true;
            }

            // show messages
            if (result?.postMessages) {
                result.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }
        }

        orderMatrixLoading.value = false;
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const prepareNote = (str) => {
        return str
            .replace('text-color--green', 'text-green-400')
            .replace('text-color--red', 'text-red-400');
    };

    const addProfitAdvanced = () => {
        if (!dataForm.settings.profit_advanced) {
            dataForm.settings.profit_advanced = [];
        };

        const profitAdvancedLength = dataForm.settings.profit_advanced.length;

        const order_i =
            !profitAdvancedLength
                ? 2
                : dataForm.settings.profit_advanced[profitAdvancedLength - 1].order_i + 1;

        dataForm.settings.profit_advanced.push({
            order_i,
            profit: -1,
        });
    };

    const deleteProfitAdvanced = i => {
        dataForm.settings.profit_advanced.splice(i, 1);
    };

    const updateOrderI = (i, $event) => {
        if ($event === compMatrixMaxCount.value) {
            dataForm.settings.profit_advanced.splice(i + 1);
        }

        dataForm.settings.profit_advanced[i].order_i = $event;
    };

    const dropProfitAdvanced = async () => {
        if (dataForm.settings.profit_advanced?.length) {
            for (let i = 0; i < dataForm.settings.profit_advanced.length; i++) {
                const value = dataForm.settings.profit_advanced[i].order_i;

                if (value > compMatrixMaxCount.value) {
                    dataForm.settings.profit_advanced[i].order_i = (compMatrixMaxCount.value).toString();
                    dataForm.settings.profit_advanced.splice(i + 1);
                    break;
                } if (value == compMatrixMaxCount.value) {
                    dataForm.settings.profit_advanced.splice(i + 1);
                    break;
                }
            }
        }
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const getDateCompoents = date => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

        return {
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
        };
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            if (id == 'start_time:check:time') {
                const date = new Date();
                
                const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

                dataForm.start_filters.push({
                    id,
                    id_op: '==',
                    value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
                    parent_id: -1,
                    changed: false,
                });
            } else if (id == 'completed_cycles:check:count') {
                dataForm.start_filters.push({ id, id_op: '==', value: 1, parent_id: -1, changed: false });
            } else {
                dataForm.start_filters.push({ id, id_op: -1, value: '', parent_id: -1, changed: false });
            }
        }
    };

    const setStartTime = (index, $event) => {
        const date = new Date($event);
        const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

        // const time = dataForm.start_filters[index].value.split(' ')[1];

        dataForm.start_filters[index].value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const changeFilterValue = (filter) => {
        delete bots.innerForms[filter];
        triggerFilter(filter);
    };

    return {
        gl,
        bots,
        refs,
        show,
        dataForm,
        botShortInfo,
        profitOptions,
        showStartTime,
        partOrderValueShow,
        compMatrixMaxCount,
        orderMatrixLoading,
        autorestartOptions,
        isSkalpingModeAvail,
        isWalletTypePercent,
        viewOrdersMatrixModal,
        showAddProfitAdvanced,
        profitAdvancedOptions,
        logarithmicFactorOptions,
        firstPartialOrderOptions,
        revenueToDepoFactorOptions,
        t,
        hasFilter,
        exchange,
        currency,
        prepareNote,
        updateOrderI,
        triggerFilter,
        setStartTime,
        changeFilterValue,
        onViewOrderMatrix,
        addProfitAdvanced,
        deleteProfitAdvanced,
        onlyAllowNumber: value => !value || /^\d+[.,]?\d{0,10}$/.test(value),
        dateDisabled(ts) {
            return new Date(ts).getTime() < (new Date().getTime() - 86400000);
        },
    };
}