<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_start_filters_title'] }}</div>
    <n-icon class="ml-2 transition transform text-base flex-grow" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
    <n-tag v-if="localStartFilters.length" type="error" size="small" round>
        {{ localStartFilters.length }}
    </n-tag>
</div>
<base-drawer-mobile
    :label="bots.localization['bot_start_filters_title']"
    v-model:show="show">
    <template #default>
        <section>
            <a
                :href="whatAreBotStartFiltersLink[refs.lang]"
                class="text-main underline hover:no-underline text-xs"
                target="_blank">
                {{ bots.localization['what_are_bot_start_filters'] || 'Что такое фильтры старта бота?' }}
            </a>
            <!-- v-if="dataForm.start_filters_enabled" -->
            <section class="mt-4">
                <n-button
                    strong
                    secondary
                    size="small"
                    class="rounded-md w-full"
                    :disabled="!dataForm.start_filters.length"
                    @click.prevent.stop="showCreateNewPreset = true">
                    {{ bots.localization?.bot_settings_filters_save_button || 'Сохранить шаблон фильтров' }}
                </n-button>
                <rb-select
                    class="w-full mt-4"
                    :label="(bots.localization?.filters_template_title || 'Шаблоны фильтров') + ':'"
                    :options="filterPresetsOptions"
                    v-model:value="filterId"
                    @update:value="changeFilterPresets" />
            </section>
            <div class="mt-4">
                <div
                    v-for="(filter, i) in localStartFilters"
                    :key="i+'_'+filter.id+'_'+filter.id_op"
                    class="mb-4"
                    :id="getHTMLid(dataForm.start_filters[filter.index].id)">
                    <n-card
                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative h-full"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <n-icon
                            class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                            @click="onDeleteStartFilter(filter.index)">
                            <DeleteDismiss24Regular />
                        </n-icon>
                        
                        <div class="mt-4">
                            <rb-select
                                class="mb-2"
                                :label="bots.localization['bot_start_filters_filter_number'] + ++i"
                                :options="startFiltersIndOptions"
                                :help="filter.help.value"
                                :value="dataForm.start_filters[filter.index].parent_id"
                                @update:value="setMainSelect($event, filter)" />

                            <template v-for="field in filter.fields.value" :key="field.name">
                                <template v-if="field.input_type == 'select'">
                                    <rb-select
                                        class="mb-2"
                                        :label="field.title"
                                        :placeholder="field.placeholder"
                                        :options="getOptions(field.options)"
                                        :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                        :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                        :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                        @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                                </template>
                                <template v-if="field.input_type == 'text'">
                                    <div v-if="field.min != undefined || field.max != undefined" class="mb-2">
                                        <div
                                            class="text-md dark:text-white/75 whitespace-nowrap label text-left"
                                            :class="!!bots.innerForms[filter.id]?.fields[field.name] ? 'text-red-500' : 'text-gray-600'"
                                            v-html="field.title">
                                        </div>
                                        <n-input-number
                                            class="mt-2"
                                            :update-value-on-input="false"
                                            :value="dataForm.start_filters[filter.index][field.name] || field.value || field.min"
                                            @update:value="dataForm.start_filters[filter.index][field.name] = $event"
                                            :placeholder="field.placeholder"
                                            :status="!!bots.innerForms[filter.id]?.fields[field.name] ? 'error' : undefined"
                                            :min="field.min"
                                            :max="field.max" />
                                        <div class="text-md text-red-400 whitespace-nowrap w-full text-left mt-4">
                                            {{ bots.innerForms[filter.id]?.fields[field.name]?.msg }}
                                        </div>
                                    </div>
                                    <template v-else>
                                        <rb-input
                                            class="mb-2"
                                            :label="field.title"
                                            :placeholder="field.placeholder"
                                            :status="!!bots.innerForms[filter.id]?.fields[field.name]"
                                            :msg="bots.innerForms[filter.id]?.fields[field.name]?.msg"
                                            :value="dataForm.start_filters[filter.index][field.name] || field.value"
                                            @update:value="dataForm.start_filters[filter.index][field.name] = $event" />
                                    </template>
                                </template>
                            </template>

                            <rb-pair-select
                                v-if="startFilterHasPair(filter.id, filter.index)"
                                :options="pairsRefs"
                                :label="bots.localization['pair_f']"
                                :value="dataForm.start_filters[filter.index].pair"
                                :placeholder="bots.localization['bots/start_filters/current_pair/label']"
                                @update:value="updateStartFilters({ i: filter.index, key: 'pair',  value: $event })" />
                        </div>
                    </n-card>
                </div>
                <n-button
                    strong
                    secondary
                    class="rounded-md w-full mt-4"
                    @click="onAddNewStartFilter">
                    + {{ bots.localization?.add_new_start_filter || 'Add new start filter' }}
                </n-button>
                <router-link class="block mt-4" :to="{ name: 'tools.presets', params: { tab: 'start-filters-presets' } }" >
                    <n-button
                        secondary
                        class="rounded-md font-semibold w-full">
                        {{ bots.localization?.bot_template_management_label || 'Управление шаблонами' }}
                    </n-button>
                </router-link>
            </div>
        </section>
    </template>
</base-drawer-mobile>
<base-drawer-mobile
    :label="bots.localization?.bot_settings_filters_save_modal_title || 'Новый шаблон фильтров'"
    v-model:show="showCreateNewPreset">
    <template #default>
        <rb-input
            class="mt-4"
            :label="newPresetModel.title.title"
            :status="newPresetModel.title.status"
            :msg="newPresetModel.title.msg"
            :placeholder="newPresetModel.title.placeholder"
            v-model:value="newPresetModel.title.value"
            @update:value="newPresetModel.title.msg = null, newPresetModel.title.status = null" />
        <rb-input
            class="mt-4"
            type="textarea"
            :label="newPresetModel.description.title"
            :status="newPresetModel.description.status"
            :msg="newPresetModel.description.msg"
            :placeholder="newPresetModel.description.placeholder"
            v-model:value="newPresetModel.description.value" />
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="loading"
            :loading="loading"
            @click="onCreateNewFiltersPreset">
            {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_save_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NTag,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NInputNumber,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbPairSelect from '@components/rb-pair-select/mobile.vue';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-start-filters-mobile',
    props: {
        pairsRefs: {
            type: Array,
            required: true,
        },
    },
    components: {
        NTag,
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        RbPairSelect,
        NInputNumber,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>