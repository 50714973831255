<template>
<div
    class="text-md text-gray-600 dark:text-white/75 font-semibold cursor-pointer text-left flex items-center"
    @click="show = !show">
    {{ bots.localization['bot/algo_auto_inversion/title'] }}
    <n-icon class="text-sm ml-2 transition transform" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="pt-4">
        <rb-checkbox
            :nowrap="false"
            :label="'- ' + bots.localization['bot/algo_auto_inversion/checkbox/title']"
            v-model:checked="dataForm.algo_autoinversion_enabled" />

        <n-alert
            v-if="messageInfo.type"
            class="mt-4 rounded-md"
            type="warning"
            size="small">
            <div class="notes text-[10px]" v-html="messageInfo.msg"></div>
        </n-alert>

        <rb-select
            v-if="botsAutoInvListOptions.length"
            class="mt-4"
            :defaultValue="-1"
            :label="bots.localization['bot/algo_auto_inversion/label']"
            :options="botsAutoInvListOptions"
            v-model:value="dataForm.algo_autoinversion_bot_id" />
        
        <section v-if="dataForm.algo_autoinversion_enabled && botsAutoInvListOptions.length" class="mt-4">
            <div class="text-md text-gray-600 dark:text-white/75 font-semibold cursor-pointer text-left flex items-center">
                {{ bots.localization['conditions_for_triggering_the_algorithm_reversal'] || 'Условия срабатывания переворачивания алгоритма' }}
            </div>
            
            <div class="mt-6 flex flex-wrap items-stretch">
                <template
                    v-for="(filter, i) in bots.startFiltersAutoInversion"
                    :key="i + filter.id">
                    <section>
                        <rb-checkbox
                            :nowrap="false"
                            :label="gitTitle(filter.id)"
                            :checked="!!~hasFilter(filter.id)"
                            :help="helpers[filter.id]"
                            @update:checked="triggerFilter(filter.id)" />

                        <div class="mt-4" v-if="filter.id == 'algo_switch:last_rate:change' && !!~hasFilter(filter.id)">
                            <rb-input
                                :label="bots.localization.percentage_of_price_change || '% изменения цены'"
                                v-model:value="dataForm.start_filters[hasFilter(filter.id)].value" />
                        </div>

                        <div class="mt-4" v-if="filter.id == 'algo_switch:rate:change' && !!~hasFilter(filter.id)">
                            <rb-input
                                :label="bots.localization.percentage_of_price_change || '% изменения цены'"
                                v-model:value="dataForm.start_filters[hasFilter(filter.id)].value" />
                        </div>

                        <div class="mt-4" v-if="filter.id == 'algo_switch:time:change' && !!~hasFilter(filter.id)">
                            <rb-input
                                :label="bots.localization.time_in_hours || 'Время в часах'"
                                v-model:value="dataForm.start_filters[hasFilter(filter.id)].value" />
                        </div>

                        <div class="mt-4" v-if="filter.id == 'algo_switch:time_fix:change' && !!~hasFilter(filter.id)">
                            <rb-input
                                :label="bots.localization.time_in_hours || 'Время в часах'"
                                v-model:value="dataForm.start_filters[hasFilter(filter.id)].value" />
                        </div>
                    </section>
                    <n-divider />
                </template>
            </div>
        </section>
    </div>
</n-collapse-transition>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NDivider,
    NCollapse,
    NCheckbox,
    NCollapseItem,
    NCollapseTransition } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import { Dismiss16Filled, CellularData220Filled, QuestionCircle20Filled, IosArrowRtl24Filled } from '@vicons/fluent';
    
export default {
    name: 'bot-auto-inversion-mobile',
    emits: [
        'update:algo_autoinversion_bot_id',
        'update:algo_autoinversion_enabled',
    ],
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        NModal,
        NSelect,
        NButton,
        RbSelect,
        NPopover,
        NDivider,
        NCollapse,
        NCheckbox,
        RbInput,
        RbCheckbox,
        NCollapseItem,
        Dismiss16Filled,
        NCollapseTransition,
        IosArrowRtl24Filled,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    props: {
        walletsRefs: {
            type: [Array, Object],
            required: true,
        },
    },
    setup() {
             
        return {
            ...general(...arguments),
        };
    },
};
</script>