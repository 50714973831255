<template>
<div
    class="text-md text-gray-600 dark:text-white/75 font-semibold cursor-pointer text-left flex items-center"
    @click="show = !show">
    {{ bots.localization['bot_auto_switch_title'] }}
    <n-icon class="text-sm ml-2 transition transform" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="pt-4">
        <n-alert
            v-if="isExchangeCoin"
            class="mt-4 rounded-md"
            type="warning"
            size="small">
            <div class="notes text-xs" v-html="bots.localization['bots/auto_switch/not_avail_for_exchange/label']"></div>
        </n-alert>
        <template v-else>
            <template v-if="dataForm.algo.value == 'long' || (dataForm.algo.value == 'short' && isExchangeFutures && !isExchangeCoin)">
                <rb-checkbox
                    :nowrap="false"
                    :label="'- ' + bots.localization['bot_auto_switch_enable_button']"
                    v-model:checked="dataForm.switch_tp.enabled" />
                <n-alert
                    class="mt-4 rounded-md"
                    type="default"
                    size="small"
                    :show-icon="false">
                    <div class="notes text-[10px]" v-html="bots.localization['bots_bot_autoswitch_info']"></div>
                </n-alert>
            </template>
            <n-alert
                v-else
                class="mt-4 rounded-md"
                type="warning"
                size="small">
                <div class="notes text-[10px]">Available only for algorithm &laquo;long&raquo;</div>
            </n-alert>
        </template>
        <div
            v-show="dataForm.switch_tp.enabled"
            v-if="(dataForm.algo.value == 'long' && !isExchangeCoin) || (dataForm.algo.value == 'short' && isExchangeFutures && !isExchangeCoin)">
            <rb-select
                class="mt-4"
                label-position="left"
                :defaultValue="-1"
                :label="bots.localization['bot_auto_switch_type_select_f']"
                :options="switchTypesOptions"
                v-model:value="dataForm.switch_tp.type" />
            <n-divider />

            <div v-if="dataForm.switch_tp.type != -1">
                <rb-checkbox
                    :nowrap="false"
                    :label="'- ' + bots.localization['bot_auto_switch_unique_checkbox']"
                    :help="bots.help['switch_check_running']"
                    v-model:checked="dataForm.switch_tp.switch_check_running" />

                <rb-checkbox
                    v-if="dataForm.switch_tp.switch_check_running"
                    class="mt-4"
                    :nowrap="false"
                    :label="'- ' + bots.localization['bots/auto_switch/switch_check_running_type']"
                    :help="bots.help['bots/auto_switch/switch_check_running_type']"
                    v-model:checked="dataForm.switch_tp.switch_check_running_type" />

                <rb-checkbox
                    class="mt-4"
                    :nowrap="false"
                    :label="'- ' + bots.localization['bots/auto_switch/search_new_pair_for_order_matrix/label']"
                    :help="bots.help['search_new_pair_for_order_matrix']"
                    v-model:checked="dataForm.switch_tp.search_new_pair_for_order_matrix" />
                <n-divider />

                <bot-signal-switch
                    v-if="dataForm.switch_tp.type === 'signal'"
                    :symbolsForMarketRefs="symbolsForMarketRefs" />

                <bot-switch-tokens-order
                    v-if="dataForm.switch_tp.type === 'user_list'"
                    :symbolsForMarketRefs="symbolsForMarketRefs" />

                <bot-pair-switch
                    v-if="dataForm.switch_tp.type === 'va'"
                    :symbolsForMarketRefs="symbolsForMarketRefs" />
            </div>
        </div>
    </div>
</n-collapse-transition>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NDivider,
    NCollapse,
    NCheckbox,
    NCollapseItem,
    NCollapseTransition } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import BotSignalSwitch from '../bot-signal-switch/mobile.vue';
import BotSwitchTokensOrder from '../bot-switch-tokens-order/mobile.vue';
import BotPairSwitch from '../bot-pair-switch/mobile.vue';

// icons
import { Dismiss16Filled, CellularData220Filled, QuestionCircle20Filled, IosArrowRtl24Filled } from '@vicons/fluent';
    
export default {
    name: 'bot-switch-trading-pair-mobile',
    props: {
        isExchangeCoin: Boolean,
        symbolsForMarketRefs: {
            type: Object,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        NModal,
        NSelect,
        NButton,
        RbSelect,
        NPopover,
        NDivider,
        NCollapse,
        NCheckbox,
        RbCheckbox,
        BotPairSwitch,
        NCollapseItem,
        Dismiss16Filled,
        BotSignalSwitch,
        NCollapseTransition,
        IosArrowRtl24Filled,
        BotSwitchTokensOrder,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // vars
        const dataForm = bots.dataForm;
        const show = ref(dataForm.switch_tp.enabled);
        const messageInfo = reactive({
            type: '',
            msg: '',
        });

        const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
        const switchTypesOptions = computed(() => bots.switchTypes.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        watch(() => dataForm.switch_tp.enabled, v => {
            v
                ? console.log('checked true')
                : hideCollapse();
        });

        const hideCollapse = () => {
            show.value = false;
        };

        return {
            gl,
            bots,
            refs,
            show,
            dataForm,
            messageInfo,
            isExchangeFutures,
            switchTypesOptions,
            t,
        };
    },
};
</script>