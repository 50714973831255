<template>
<n-collapse arrow-placement="right">
    <n-collapse-item class="ml-0">
        <template #header>
            <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bot_additional_settings_title'] }}</div>
        </template>
        <div>
            <!-- scalping -->
            <template v-if="isSkalpingModeAvail">
                <rb-checkbox
                    class="mt-4"
                    :label="'- ' + bots.localization['bots/additional_settings/scalping/label']"
                    :help="bots.help['bots/additional_settings/scalping/label']"
                    v-model:checked="dataForm.settings.scalping" />
                <n-divider class="my-4" />
            </template>
            
            <!-- revenue_to_depo_factor -->
            <template v-if="!isWalletTypePercent">
                <section>
                    <rb-checkbox
                        :label="'- ' + bots.localization['bot_additional_settings_add_revenue_to_depo_checkbox']"
                        :help="bots.help['add_revenue_to_depo']"
                        v-model:checked="dataForm.settings.revenue_to_depo" />

                    <rb-select
                        v-if="dataForm.settings.revenue_to_depo"
                        class="mt-4"
                        :defaultValue="-1"
                        :options="revenueToDepoFactorOptions"
                        v-model:value="dataForm.settings.revenue_to_depo_factor" />
                </section>
                <n-divider class="my-4" />
            </template>

            <!-- logarithmic_factor -->
            <!-- v-if="!isWalletTypePercent" -->
            <section>
                <rb-checkbox
                    :label="'- ' + bots.localization['bot_additional_settings_logarithmic_scale_checkbox']"
                    :help="bots.help['logarithmic_scale_for_orders']"
                    v-model:checked="dataForm.settings.logarithmic_scale" />

                <rb-select
                    v-if="dataForm.settings.logarithmic_scale"
                    class="mt-4"
                    :defaultValue="-1"
                    :label-width="345"
                    :label="bots.localization['bot_additional_settings_logarithmic_scale_factor']"
                    :options="logarithmicFactorOptions"
                    v-model:value="dataForm.settings.logarithmic_factor">
                    <template #afterTitle>
                        <n-popover
                            trigger="hover"
                            placement="top"
                            class="bg-main rounded-md">
                            <template #trigger>
                                <n-button
                                    strong
                                    size="tiny"
                                    class="ml-2"
                                    :color="gl.mainColor"
                                    :loading="orderMatrixLoading"
                                    :disabled="dataForm.settings.logarithmic_factor == -1 || orderMatrixLoading"
                                    @click="onViewOrderMatrix(dataForm.settings.order_matrix)">
                                    <n-icon class="text-xl text-white">
                                        <CellularData220Filled />
                                    </n-icon>
                                </n-button>
                            </template>
                            <span class="text-white text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_button_title']"></span>
                        </n-popover>
                    </template>
                </rb-select>
                </section>
            <n-divider class="my-4" />

            <!-- cost_limit_enabled -->
            <section>
                <rb-checkbox
                    :label="'- ' + bots.localization['bot_additional_settings_cost_limit_checkbox']"
                    :help="bots.help['cost_limit']"
                    v-model:checked="dataForm.settings.cost_limit_enabled" />
                <div class="mt-4">
                    <n-input
                        v-if="dataForm.settings.cost_limit_enabled"
                        clearable
                        class="rounded-md text-left"
                        type="text"
                        :placeholder="null"
                        :allow-input="onlyAllowNumber"
                        v-model:value="dataForm.settings.cost_limit"/>
                </div>
            </section>
            <n-divider class="my-4" />

            <!-- fix_first_partial_order -->
            <template v-if="!dataForm.settings.simulate">
                <section>
                    <rb-checkbox
                        :label="'- ' + bots.localization['bot_additional_settings_fix_first_partial_checkbox']"
                        :help="bots.help['fix_first_partial_order_with_profit']"
                        v-model:checked="dataForm.settings.fix_first_partial_order" />

                    <rb-select
                        v-if="dataForm.settings.fix_first_partial_order"
                        class="mt-4"
                        :defaultValue="-1"
                        :options="firstPartialOrderOptions"
                        v-model:value="dataForm.settings.fix_first_partial_order_value" />
                </section>
                <n-divider class="my-4" />
            </template>

            <!-- autorestart -->
            <rb-select
                class="mt-4"
                :nowrap="false"
                :defaultValue="-1"
                :label="bots.localization['bots/settings/autorestart/label']"
                :options="autorestartOptions"
                v-model:value="dataForm.settings.autorestart" />

            <template v-if="partOrderValueShow">
                <n-divider class="my-4" />
                <rb-checkbox
                    :label="'- ' + (bots.localization['bots/additional_settings/change_profit_setting/label'] || 'Изменить настройку профита')"
                    :help="bots.help['bots/additional_settings/change_profit_setting/help'] || 'Help'"
                    v-model:checked="dataForm.settings.profit_advanced_enabled" />

                <section class="mt-4">
                    <n-card
                        v-for="(item, i) in dataForm.settings.profit_advanced"
                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md relative w-full mb-2"
                        :key="i"
                        :bordered="false"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }"
                        content-style="padding: 0;">
                        <section class="p-3">
                            <n-icon
                                v-if="i"
                                class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                                @click="deleteProfitAdvanced(i)">
                                <DeleteDismiss24Regular />
                            </n-icon>
                            
                            <div class="flex mt-4 items-end">
                                <!-- order_i -->
                                <rb-select
                                    class="w-6/12 pr-1"
                                    :label="bots.localization['bots/additional_settings/order_number/label'] || 'Номер ордера'"
                                    :options="profitAdvancedOptions[i]"
                                    :value="item.order_i"
                                    @update:value="updateOrderI(i, $event)" />
                                <!-- profit -->
                                <rb-select
                                    class="w-6/12 pl-1"
                                    :defaultValue="-1"
                                    :label="bots.localization['bot_profit_f']"
                                    :help="bots.help['profit']"
                                    :options="profitOptions"
                                    v-model:value="item.profit" />
                            </div>
                        </section>
                    </n-card>
                    <n-button
                        v-if="showAddProfitAdvanced"
                        secondary
                        type="default"
                        class="w-full rounded-md shadow mt-2"
                        @click="addProfitAdvanced">
                        +
                    </n-button>
                </section>
            </template>

            <n-divider class="my-4" />
            <section class="flex flex-wrap items-center" id="start_time_check_time">
                <rb-checkbox
                    class="mr-2"
                    :label="bots.localization['run_the_bot_after_the_specified_time'] || 'Запустить бота после указанного времени'"
                    :help="bots.help['run_the_bot_after_the_specified_time_help'] || 'Бот будет запущен в указанное время'"
                    :checked="!!~dataForm.start_filters.findIndex(({ id }) => id == 'start_time:check:time')"
                    :status="!!bots.innerForms['start_time:check:time'] ? 'error' : undefined"
                    @update:checked="changeFilterValue('start_time:check:time')" />
                    
                <template v-if="!!~hasFilter('start_time:check:time')">
                    <n-popover
                        v-model:show="showStartTime"
                        class="rounded-md"
                        placement="bottom-end"
                        trigger="click">
                        <template #trigger>
                            <n-button
                                secondary
                                type="default"
                                class="rounded-md flex items-center px-3 w-full mt-4"
                                @click="showStartTime = true">
                                <div class="w-full flex justify-between items-center font-semibold">
                                    <span class="mr-2">
                                        {{ dataForm.start_filters[hasFilter('start_time:check:time')].value }}
                                    </span>
                                    <n-icon size="20">
                                        <CalendarLtr32Regular />
                                    </n-icon>
                                </div>
                            </n-button>
                        </template>
                        <section class="flex">
                            <n-date-picker
                                panel
                                class="mt-2"
                                type="datetime"
                                size="large"
                                :actions="null"
                                :is-date-disabled="dateDisabled"
                                :value="new Date(dataForm.start_filters[hasFilter('start_time:check:time')].value).getTime()"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @update:value="setStartTime(hasFilter('start_time:check:time'), $event)" />
                        </section>
                    </n-popover>
                </template>

                <div class="text-md text-red-400 whitespace-nowrap w-full text-left mt-4">
                    {{ bots.innerForms['start_time:check:time']?.fields.value.msg }}
                </div>
            </section>

            <n-divider class="my-3" />
            <section id="completed_cycles_check_count">
                <rb-checkbox
                    class="mr-2"
                    :label="bots.localization['stop_the_bot_after_n_completed_cycles'] || 'Остановить бота после N завершенных циклов'"
                    :help="bots.help['stop_the_bot_after_n_completed_cycles_help'] || 'Бот остановится, после того как завершит указанное количество циклов'"
                    :checked="!!~dataForm.start_filters.findIndex(({ id }) => id == 'completed_cycles:check:count')"
                    :status="!!bots.innerForms['completed_cycles:check:count'] ? 'error' : undefined"
                    @update:checked="changeFilterValue('completed_cycles:check:count')" />

                <template v-if="!!~hasFilter('completed_cycles:check:count')">
                    <rb-input-number
                        :min="1"
                        class="mt-2"
                        v-model:value="dataForm.start_filters[hasFilter('completed_cycles:check:count')].value" />
                </template>

                <div class="text-md text-red-400 whitespace-nowrap w-full text-left mt-4">
                    {{ bots.innerForms['completed_cycles:check:count']?.fields.value.msg }}
                </div>
            </section>
        </div>
    </n-collapse-item>
</n-collapse>

<base-drawer-mobile
    :label="bots.localization['bot_additional_settings_logarithmic_scale_view_orders_matrix_button']"
    v-model:show="viewOrdersMatrixModal">
    <template #default>
        <section>
            <div class="flex items-center justify-start">
                <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_current']"></span>
                <span
                    class="inline-block rb-td-stock-icon h-6 mx-2"
                    :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`" >
                </span>
                <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_tickers_for']"></span>
                <div class="flex items-center mx-4">
                    <rb-coin class="w-6" :coin="currency(String(botShortInfo.pair).split('/').shift().trim())" />
                    <div class="text-xs text-gray-600 dark:text-white/75 ml-2">{{ botShortInfo.pair }}</div>
                </div>
            </div>
                
            <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_buy']"></span>&nbsp;
            <strong class="text-xs pl-1 text-green-400">{{ botShortInfo.buy }}</strong>
            <span class="text-xs pl-3" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_sell']"></span>&nbsp;
            <strong class="text-red-500 text-xs pl-1">{{ botShortInfo.sell }}</strong>
            <div class="mt-4 notes text-xs">
                <div v-if="dataForm.algo.value == 'long'" v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_long_info'])"></div>
                <div v-else v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_short_info'])"></div>
            </div>
        </section>
        <n-divider />

        <section>
            <div
                v-for="(item, i) in botShortInfo.orders_matrix"
                :key="i"
                class="text-xs mb-3">
                <div>
                    <span class="mr-2">{{ i + 1 }}.</span>
                    <span class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_amount'] }}</span>
                    <span class="mr-2 text-main">{{ item.amount }}</span>
                </div>
                <div>
                    <strong class="mr-2">{{ dataForm.algo.value === 'long' ? 'Buy' : 'Sell' }}</strong>
                    <span class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_rate'] }}</span>
                    <span class="mr-2 text-green-400">{{ item.rate }}</span>
                </div>
            </div>
        </section>
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :loading="orderMatrixLoading"
            :disabled="orderMatrixLoading"
            @click="onViewOrderMatrix">
            {{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_update'] }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NInput,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    NDivider,
    NCollapse,
    NCheckbox,
    NDatePicker,
    NCollapseItem } from 'naive-ui';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import {
    Dismiss16Filled,
    CellularData220Filled,
    DeleteDismiss24Regular,
    QuestionCircle20Filled } from '@vicons/fluent';
    
export default {
    name: 'botAdditionalSettings',
    emits: [
        'update:some',
    ],
    components: {
        NIcon,
        NCard,
        NAlert,
        NInput,
        NModal,
        NSelect,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        NCollapse,
        NCheckbox,
        RbCheckbox,
        NDatePicker,
        NCollapseItem,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    props: {
        walletsRefs: {
            type: [Array, Object],
            required: true,
        },
    },
    setup(props) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>